import { store } from "../store";
import { authChange } from "../store/Auth";
import { HttpHandler } from "./HttpHandler";
import { HttpRequest as BaseHttpRequest } from "./HttpRequest";
export class HttpRequest extends BaseHttpRequest {
  store = store;
  errorHandler = (statusCode: number, error: HttpHandler): HttpHandler => {
    if (statusCode === 401) {
      store.dispatch(authChange());

      window.location.href = "https://dev-cp.dehub.mn/login";
    }

    throw error;
  };
}

function currencyFormat(num: number) {
  return (
    parseFloat(`${num}`)
      ?.toFixed(2)
      ?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + " ₮"
  );
}

export { currencyFormat };
